@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "~animate.css";
@layer components {
  .label {
    @apply block text-xs px-4;
  }
  .input {
    @apply block border border-gray-200 rounded-full py-2 px-4 w-full mb-2;
  }
  .input:focus {
    @apply outline-none ring-2;
  }
  .textarea {
    @apply block border border-gray-200 rounded-lg py-2 px-4 w-full mb-2;
  }
  .textarea:focus {
    @apply outline-none ring-2;
  }

  .ms-btn:focus {
    @apply outline-none ring-2;
  }

  .ms-btn-black {
    @apply text-sm rounded-full px-3 py-1 bg-black text-white focus:outline-none hover:bg-blue-500 transition-colors duration-150 ease-in-out focus:ring-2 focus:bg-blue-500;
  }

}

[x-cloak] { display: none; }

[wire\:offline] {
  display: none;
}

[wire\:dirty]:not(textarea):not(input):not(select) {
  display: none;
}


